import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import anime from 'animejs';
import {useEffect, useRef} from 'react';

function IBP(){
    const ref_left = useRef(null);
    const ref_right = useRef(null);
    const ref_video = useRef(null);

    const IBP_right = <svg width="580" ref={ref_right} className={"abs top right"}  height="613" viewBox="0 0 580 613" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path  d="M620 160.075C527.657 160.075 452.925 85.1899 452.925 -7M272.986 -6.99998C272.986 184.577 428.269 340.014 620 340.014M19.9999 -6.99996C20 324.394 288.606 593 620 593" stroke="#3BAB54" stroke-width="40" stroke-miterlimit="10"/>
    </svg>;

    const IBP_left = <svg ref={ref_left} className={"abs bottom left"} width="574" height="586" viewBox="0 0 574 586" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-46 452.925C46.343 452.925 121.075 527.81 121.075 620M301.014 620C301.014 428.423 145.73 272.986 -46 272.986M554 620C554 288.606 285.394 20 -46 20" stroke="#3BAB54" stroke-width="40" stroke-miterlimit="10"/>
    </svg>

    

    const animate = () => {
        anime.timeline({loop:true}).add({
            targets: [ref_left.current.querySelectorAll('path'), ref_right.current.querySelectorAll('path') ],
            opacity: [0,  1],
            easing: 'easeOutExpo',
            duration: 2800
        }).add({
            targets: [ref_left.current.querySelectorAll('path'), ref_right.current.querySelectorAll('path') ],
            strokeDashoffset: [anime.setDashoffset, 0  ],
            easing: 'linear',
            duration: 2800
        }).add({
            targets: [ref_left.current.querySelectorAll('path'), ref_right.current.querySelectorAll('path') ],
            opacity: [1,  0],
            easing: 'easeOutExpo',
            duration: 2800
        });

        anime.timeline({loop:false}).add({
            targets: ref_video.current,
            opacity: [0, 1],
            translateY: [100, 0],
            easing: 'easeOutExpo',
            duration: 2000

        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref_left.current) {
        observer.observe(ref_left.current);
        }

        return () => {
        if (ref_left.current) {
            observer.unobserve(ref_left.current);
        }
        };
    }, []);
    return(
        <div id="IBP" className="flex-container row wrap center justify" >
            <div className="card half-width flex justify">
                {IBP_left}
                {IBP_right}
                <video ref={ref_video} className="align-bottom"autostart autoPlay muted loop>
                    <source src="./media/IBP.webm" type="video/webm"/>
                </video>
            </div>
            
            <div className="flex col half-width between" >
                <div></div>
                <div></div>
                <div>
                    <h3>Infrastructure Builders Program</h3>
                    <h3 className="body">An incentive program built to onboard RPC service providers in an effort to support the Polkadot infrastructure through providing globally distributed infrastructure services.</h3>
                    <Button text="Learn More" alt={"alt"} href="https://ibp.network/" target="_blank" />
                </div>
                <div>
                    <SmallHeader text={"Supported by"}/>
                    <img src="./img/Polkadot_Grey.svg"/>
                </div>
                <div></div>
            </div>


        </div>
    );
}





export default IBP;