import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing';
import IBP from './pages/IBP';
import Testnets from './pages/Testnets';
import Delegate from './pages/Delegate';
import Navbar from './components/navbar';
import Vision from './pages/Vision';
import Networks from './pages/Networks';
import Footer from './components/footer';
import Modal from './pages/Modal';
import {useState,useEffect} from 'react';

function App() {
  const dot_modal = <Modal props={polkadot_steps}/>;
  const ksm_modal = <Modal props={kusama_steps}/>;
  const movr_modal = <Modal props={moonriver_steps}/>;

  const [modal_wrapper, hide_wrapper] = useState('none');
    
  useEffect(()=> {
      window.onclick = function(event){
        if(event.target.classList.contains('modal-wrapper')){
          var ksm_modal = document.getElementById(`modal-Kusama`);
          var dot_modal = document.getElementById(`modal-Polkadot`);
          var movr_modal = document.getElementById(`modal-Moonriver`);
          ksm_modal.style.display = 'none';
          movr_modal.style.display = 'none';
          dot_modal.style.display = 'none';
        }
    
          
      }
  }, []);

  return (
    <div className="wrapper">
      <Navbar/>
      <div id={`modal-Kusama`} className={`modal-wrapper flex center justify`} style={{display: modal_wrapper}}>
        {ksm_modal}
      </div>

      <div id={`modal-Moonriver`} className={`modal-wrapper flex center justify`} style={{display: modal_wrapper}}>
        {movr_modal}
      </div>

      <div id={`modal-Polkadot`} className={`modal-wrapper flex center justify`} style={{display: modal_wrapper}}>
        {dot_modal}
      </div>

      <div id={`modal-Polimec`} className={`modal-wrapper flex center justify`} style={{display: 'none', width: 0, height: 0}}>
      </div>
      
      <Landing/>
      <Vision/>
      <IBP/>
      <Testnets/>
      <Delegate/>
      <Networks/>
      <Footer/>
    </div>
  );
}

const polkadot_steps = {
  name: 'Polkadot',
  steps: ['Go to staking.polkadot.network/. Connect to the Polkadot network found on the left sidebar', 'Connect your wallet by clicking the icon on the top right corner & select your preferred wallet application', 'Click the Accounts icon located on the top right corner of the site & select your account',
          'In the left sidebar, select Community. Find Stake Plus & click Validators', 'Click the heart icon on the Stake Plus to add to your favourites.', 'In the left sidebar, select Nominate & Select Start Nominating', 
          'Select Manual Selection & select Add from Favourites. Select StakePlus. Nominate preferred Staking Amount & Stake'],
  src: ['Comp 1', 'Comp 2', 'Comp 3', 'Comp 4', 'Comp 5', 'Comp 6', 'Comp 7'],
  src_folder:'polkadot_steps'

}

const kusama_steps = {
  name: 'Kusama',
  steps: ['Go to staking.polkadot.network/. Connect to the Kusama network found on the left sidebar', 'Connect your wallet by clicking the icon on the top right corner & select your preferred wallet application', 'Click the Accounts icon located on the top right corner of the site & select your account',
          'In the left sidebar, select Community. Find Stake Plus & click Validators', 'Click the heart icon on the Stake Plus to add to your favourites.', 'In the left sidebar, select Nominate & Select Start Nominating', 
          'Select Manual Selection & select Add from Favourites. Select StakePlus. Nominate preferred Staking Amount & Stake'],
  src: ['Comp 1', 'Comp 2', 'Comp 3', 'Comp 4', 'Comp 5', 'Comp 6', 'Comp 7'],
  src_folder:'polkadot_steps'
}

const moonriver_steps = {
  name: 'Moonriver',
  steps: ['Go to apps.moonbeam.network. Connect to the Moonriver network found on the top right corner', 'Connect your wallet by selecting between EVM wallets or Substrate wallets', 'Click Select Collator in Stake options and search for Stake Plus. A minimum of 5 MOVR is required to stake! '],
  src: ['Comp 8', 'Comp 9', 'Comp 10'],
  src_folder:'moonriver_steps'
}



export default App;
