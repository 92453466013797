import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import {useEffect, useRef, useState} from 'react';
import anime from 'animejs';

function Modal({props}){  


    return(
    <ModalBody props={props} />     
    );
}


function ModalBody({props}){
    var dot_steps = [];
    var step_count = [];

    for(var i = 0; i < props.steps.length; i++){
        dot_steps.push(<ModalSteps max={props.steps.length} index={i+1} name={props.name} desc={props.steps[i]} src={props.src[i]} src_folder={props.src_folder}/>);
        step_count.push(<a  href={`#${props.name}-slide-${i + 1}`}>{i+1}</a>)
    }
    
    return(
    <div class="slider">
        <div class="slides">
            {dot_steps}
        </div>
        {step_count}
    </div>
    );
}

function ModalSteps({index, name, desc, src, src_folder, max}){
    var next_button;
    var prev_button;
    if(index > 1){
        prev_button = <Button text={"prev"} alt={'alt small'} href={`#${name}-slide-${index - 1}`} />;
    }
    if(index < max){
        next_button = <Button text={"next"} alt={'alt small'} href={`#${name}-slide-${index + 1}`} />;
    }
    
    return(
    <div id={`${name}-slide-${index}`}>
        <div className="flex col">
            <div className="flex row center">
                <h3 style={{minHeight: '3rem'}}>Step {index}: </h3>

            </div>
            
            {<Step desc={desc} src={src} folder={src_folder} next={next_button} prev={prev_button}/>}
           
        </div>
    </div>);
}
function Step({desc, src, folder, next, prev}){
    return(
        <div className="flex row" >
            <div className="flex col" style={{height: '90%'}}>
                <p className="modal-step alt">{desc}</p>
                <div className="flex row align-bottom">
                    {prev}
                    {next}
                </div>
            </div>
            
            <video className="modal-video " autostart autoPlay muted loop>
                <source src={`./media/${folder}/${src}.webm`} type="video/webm"/>
            </video>
            
        </div>
    )
}
export default Modal;