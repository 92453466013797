import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import {useEffect, useRef} from 'react';
import anime from 'animejs';

function Delegate(){
    const ref = useRef(null);
    /*  
    const animate = () => {
        anime.timeline().add({
            targets: ref.current.querySelector('svg path'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeOutExpo',
            duration: 140000,
        });


    }
    */

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                //animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <div id="delegate" className="container" style={{paddingBottom: '0rem'}}>
            <div className={"start-1 row-1 end-12"}>
                <div className="flex row wrap item">
                    <div className="flex col half-width">
                        <H2 text={"We Believe in You to"}/>
                        <H2 text={"Believe in Us"} alt={'alt'} />
                    </div>

                    <div className="flex col align-right half-width">
                        <h3 className="align-right body">
                            Play a part in the development of the Web3 landscape through Polkadot Governance and build upon innovation.
                        </h3>
                        <Button text={'Delegate'} alt={'alt'} target="_blank" href="https://delegation.polkadot.network/#/152QidDC4QrtMCyRGiQmvrNyjntvMg2XouCSdoPSeqUNTvsq"/>
                    </div>
                </div>
            </div>

            <div className={"start-1 row-8 end-13"}>
                <div className={"card max-width max-height flex justify"}>

                    <img className="abs left top" src="./img/Delegate_Left.svg"/>
                    <img className="abs right top" src="./img/Delegate_Right.svg"/>

                    <img className={'align-bottom'} style={{width: '90%', zIndex: 0}} src={"./img/Subsquare.png"}/>
                </div>
            </div>


        </div>
    );
}

export default Delegate;