import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import {useEffect, useRef} from 'react';
import anime from 'animejs';

function Networks(){
    const ref = useRef(null);
    /*  
    const animate = () => {
        anime.timeline().add({
            targets: ref.current.querySelector('svg path'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeOutExpo',
            duration: 140000,
        });


    }
    */

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                //animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <>
        <div id="networks" className="container">
            <div className={"start-1 row-1 end-12"}>
                <div className="flex row item">
                    <div className="flex col half-width">
                        <H2 text={"Stake to Our"}/>
                        <H2 text={"Suite of Networks"} alt={'alt'} />
                    </div>
                </div>
            </div>

            <div className={"flex wrap start-1 row-6 end-13"} style={{gridRowEnd:'18'}}>
                <Network name="Polkadot" ticker={"DOT"} apr={"~10.05%"} total={''} desc="Polkadot unites a network of heterogeneous blockchains called parachains and parathreads. "/>
                <Network name="Kusama"   ticker={"KSM"} apr={"~10.05%"} total="34,575 KSM" desc="Kusama is a scalable network of specialized blockchains built using Substrate and nearly the same codebase as Polkadot. "/>
                <Network name="Moonriver" ticker={"MOVR"} apr={"10%"} total="24,805 MOVR" desc="Moonbeam’s Kusama deployment that serves as a “CanaryNet” to the Moonbeam Network. "/>
                <Network name="Polimec" ticker={"PLMC"} apr={"--%"} total='Coming Soon' desc="Polimec enables fundraising in a decentralized, transparent, and regulatory compliant manner."/>
            </div>
        </div>
        </>
    );
}

function Network({name, ticker, apr, total, desc}){
    var total_block;
    if(total !== ''){
        total_block =
        <>                    
        <h5>{total}</h5>
        <p className="label">total staked</p>
        </> 
    ;
    }
    
    function openModal(){
        var modal = document.getElementById(`modal-${name}`);
        modal.style.display = 'flex';
        
    }
    return(
        <div onClick={openModal} id={`stake-${name}`} className="network flex row">
            <div className="flex col">
                <div className="flex between">
                    <div className="flex col">
                        <h5>{name}</h5>
                        <p className="label">{ticker}</p>
                    </div>

                    <div className="flex col">
                        <h5 className="alt">{apr}</h5>
                        <p className="label">APR</p>
                    </div>
                    <div className="flex col">
                    {total_block}
                    </div>

                </div>

                <div className="align-bottom">
                    <p className="label">{desc}</p>
                </div>
            </div>
            
            <div className="network-img flex center justify align-right" style={{backgroundColor: `var(--${name})`}}>
                <img src={`./img/logos/${ticker}.svg`}/>
                <div className="stake-icon-container abs center ">
                    <div className="stake-icon flex center justify">
                        <img src="./img/icons/new_window.svg"/>
                    </div>
                </div>
            </div>

            <div className="network-cover abs left top"></div>

        </div>
    )

}


export default Networks;