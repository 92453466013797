import {H2, H1, H4} from '../components/headers';
import {useRef, useEffect} from 'react';
import anime from 'animejs';

function Vision(){
    
    return(
        <div id="mission" className="grid max-width partial-height alt">
            <div className="flex start-1 end-6 center">
                <H2 text={"Our Vision"}/>
            </div>

            <div className="flex start-6 end-13 center">
                <H2 text={"Everyone should be able to harness the power of Web3 to collectively build & decide on the world we all want to see."} alt={'alt'}/>
            </div>
        </div>
    );
}

export default Vision;