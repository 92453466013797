import {H2, H1, H4} from '../components/headers';
import {Button} from '../components/buttons';
import {useRef, useEffect} from 'react';
import anime from 'animejs';

function Landing(){    
    const ref = useRef(null);
    const box_ref = useRef(null);
    /*
    const animate = () => {
        anime.timeline().add({
            targets: box_ref.current,
            width: ['0%', '100%'],
            easing:'easeOutExpo',
            duration: 1400, 
        });

        anime.timeline({loop:true}).add({
            targets: ref.current.querySelectorAll('.project'),
            
        })
    }
    */

    useEffect(() => {
       //animate();
    }, []);
    return(
        <div className="container alt" style={{position: 'relative'}}>
            <video id="landing-video"  className="abs bottom" autostart autoPlay muted loop>
                <source src="./media/landing.webm" type="video/webm"/>
            </video>
            <div className={"start-1 row-9 end-13"} style={{zIndex: 1}}>
                <div className="flex wrap row max-width">
                    <div className="flex col half-width">
                        <H1 text={"Stake, Vote & Build"}/>
                        <H1 text={"Web3 Together"} alt={true} />
                        <a className="button">Stake Now</a>
                    </div>

                    <div className="flex half-width">
                        <p className="flex align-right half-width">
                            harness the power of Web3 through staking from our suite of protocols or by empowering our community through delegated governance
                        </p>
                    </div>
                </div>

            </div>

            
                <a href="#mission" className="abs center bottom button dark">
                    <img className="abs center top " src="./img/icons/down_arrow.svg"/>
                </a>
           
        </div>
    );
}

export default Landing;