import {useEffect, useRef} from 'react';
import {Button} from './buttons';
import anime from 'animejs';

function Navbar(){
    const nav_logo = useRef(null);
    const nav_items = useRef(null);
    const animate = () => {
        anime.timeline({loop: false}).add({
            targets: nav_logo.current.querySelector('.logo'),
            translateY: [100, 0],
            duration: 1000,
            easing: 'easeOutExpo'
        });
        anime.timeline({loop:false}).add({
            targets: nav_items.current.querySelectorAll('.nav-item h4'),
            translateY: [100, 0],
            duration: 1000,
            easing: 'easeOutExpo',
            delay: (el, i) => 100 * i
        });
    };

    useEffect(() => {
        animate();
    }, []);
    return(
        <div className={"navbar grid"}>
            <div className="start-1 end-13 flex center">
                <div ref={nav_logo} className="hide-overflow inline-flex" style={{paddingRight: '2rem'}}>
                    <a href="/" className={"logo flex center row"}>
                        <img src="./img/logo.svg"/>
                    </a>
                </div>

                <div className={"flex row center max-height"}>
                    <div ref={nav_items} style={{position: 'relative'}} className={"flex row center align-right"}>
                        <a href="#mission" className="nav-item flex col">
                            About
                        </a>

                        <a href="#IBP" className="nav-item">
                            Community
                        </a>

                        <a href="#networks" className="nav-item">
                            Networks
                        </a>
                    </div>
                </div>

                <div className={"flex row center max-height align-right"}>
                    <div ref={nav_items} style={{position: 'relative'}} className={"flex row center align-right"}>
                        <Button href="https://delegation.polkadot.network/#/152QidDC4QrtMCyRGiQmvrNyjntvMg2XouCSdoPSeqUNTvsq" target="_blank" text={"Delegate"} alt={"alt"}/>
                        <Button href="#networks" text={"Stake Now"} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Navbar;