import {H1, H3, SmallHeader} from './headers.js';
import {Button} from './buttons';

function Footer(){
    return(
        <div className={"footer"}>
            <div className="flex col start-1 row-3 end-4">
                <SmallHeader text={'networks'}/>
                <div className="flex row">
                    <div className="flex col" style={{marginRight: '1rem'}}>
                        <a className="nav-item" href="https://polkadot.network/" target="_blank">Polkadot</a>
                        <a className="nav-item" href="https://kusama.network/" target="_blank">Kusama</a>
                        <a className="nav-item" href="https://moonbeam.network/networks/moonriver/" target="_blank" >Moonriver</a>
                        <a className="nav-item" href="https://www.polimec.org/" target="_blank">Polimec</a>
                    </div>

                    <div className="flex col">
                        <a className="nav-item" href="https://acala.network/" target="_blank">Acala</a>
                        <a className="nav-item" href="https://bsx.fi/" target="_blank">Basilisk</a>
                        <a className="nav-item" href="https://bifrost.finance/" target="_blank">Bifrost</a>
                        <a className="nav-item" href="https://parallel.fi/" target="_blank">Parallel Fi</a>
                        <a className="nav-item" href="https://moonbeam.network/networks/moonbeam/" target="_blank">Moonbeam</a>
                    </div>
                </div>
            </div>

            <div className="flex col start-4 row-3 end-7">
                <SmallHeader text={'testnets'}/>
                <div className="flex row">
                    <div className="flex col" style={{marginRight: '1rem'}}>
                        <a className="nav-item" >Hyperbridge</a>
                        <a className="nav-item" href="https://www.availproject.org/" target="_blank">Avail</a>
                        <a className="nav-item" href="https://artela.network/" target="_blank">Artela</a>
                    </div>
                </div>
            </div>

            <div className="flex col start-7 row-3 end-10">
                <SmallHeader text={'socials'}/>  
                <div className="flex col" style={{marginRight: '1rem'}}>
                    <a className="nav-item" href="https://twitter.com/StakePlusCrypto" target="_blank">Twitter</a>
                    <a className="nav-item" href="https://matrix.to/#/@stakeplus:matrix.org" target="_blank">Element</a>
                </div>
            </div>

            <img id="footer-logo" className="abs bottom align-right" src="./img/logo-large.svg"/>
        </div>
    );
};

export default Footer;