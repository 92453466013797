import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import anime from 'animejs';
import {useEffect, useRef} from 'react';

function Testnets(){
    const ref = useRef(null);

    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current.querySelectorAll('.morph path'),
            strokeDashoffset: [anime.setDashoffset, 0 ],
            easing: 'easeOutExpo',
            duration: 2800
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);
    return(
        <div id="testnets" className="flex-container row wrap center justify" >
            <div className="card flex col center half-width full-height flex justify">

                <img className="abs left bottom" src="./img/Testnet_Left.svg"/>
                <img className="abs right top"   src="./img/Testnet_Right.svg"/>

                <div className="flex center justify">
                    <img className="abs logo"  src="./img/logos/Artela.svg"/>
                    <video autostart autoPlay muted loop>
                        <source src="./media/Artela_Banner.mp4" type="video/mp4"/>
                    </video>
                </div>

                <div className="flex center justify">
                    <img className="abs logo" src="./img/logos/Avail-Secondary.png"/>
                    <video autostart autoPlay muted loop>
                        <source src="./media/Avail_Banner.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
            
            <div className="flex col half-width full-height between" >
                <div></div>
                <div></div>
                <div>
                    <h3>Testnets & Public-Goods Facilitation</h3>
                    <h3 className="body">A personal initiative to empower builders by providing the essential testing ground for future tools, innovations & applications to define the next Web3 landscape.</h3>
                </div>
                <div className="supporters">
                    <SmallHeader text={"Supported by"}/>
                    <img src="./img/Artela_Grey.svg"/>
                    <img src="./img/Avail_Grey.svg"/>
                </div>
                <div></div>
            </div>


        </div>
    );
}

export default Testnets;